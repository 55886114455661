import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

// Material Dashboard 2 React themes
import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "./assets/theme-dark";
import themeDarkRTL from "./assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "./routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useDispatch } from "react-redux";
import { actionTypes } from "./Redux/action.types";

const App = () => {
  const dispatchChamber = useDispatch();
  let storedServiceId: string | string[] | number[] = [];

  try {
    const storedData = localStorage.getItem("service_ids");
    if (storedData) {
      storedServiceId = JSON.parse(storedData);
    }
  } catch (error) {
    console.error("Error parsing service_ids from localStorage:", error);
  }

  // Set up state with an appropriate type to handle string, array or null
  const [serviceId, setServiceId] = useState<
    string | string[] | number[] | null
  >(storedServiceId);

  // Function to get service_ids with a 1-second delay
  const getServiceIdsWithDelay = () => {
    return new Promise<string | null>((resolve) => {
      setTimeout(() => {
        resolve(localStorage.getItem("service_ids"));
      }, 1000);
    });
  };

  useEffect(() => {
    const fetchServiceId = async () => {
      try {
        const delayedServiceIds = await getServiceIdsWithDelay(); // Wait for 1 seconds before accessing localStorage
        if (delayedServiceIds) {
          storedServiceId = JSON.parse(delayedServiceIds);
          if (storedServiceId?.length) {
            await dispatchChamber({
              type: actionTypes.GETservice_id_SUCCESS,
              payload: storedServiceId,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching service ID:", error);
      }
    };

    // Only update the state if it's different from the current serviceId in the state
    if (
      storedServiceId?.length &&
      JSON.stringify(storedServiceId) !== JSON.stringify(serviceId)
    ) {
      setServiceId(storedServiceId);
    }

    fetchServiceId();
  }, [storedServiceId, dispatchChamber, serviceId]);

  // Memoize routes based on serviceId
  const { superAdminRoutes, adminRoutes, userRoutes, notLoggedInRoutes } =
    useMemo(() => routes(serviceId), [serviceId]);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState<any>(null);
  const { pathname } = useLocation();
  const isSignInOrSignOut = pathname === "/authentication/sign-in";

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    const handleScrollToTop = () => {
      document.documentElement.scrollTop = 0;
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
    };

    handleScrollToTop();
  }, [pathname]);

  const getRoutes = (routes: any) => {
    return routes.map((route: any) => {
      if (route?.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });
  };

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  const currentRole = localStorage.getItem("role");
  const currentRoutes =
    currentRole === "superadmin"
      ? superAdminRoutes
      : currentRole === "admin"
      ? adminRoutes
      : currentRole === "user"
      ? userRoutes
      : notLoggedInRoutes;

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && !isSignInOrSignOut && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Material Dashboard 2"
              routes={currentRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(currentRoutes)}
          {localStorage.getItem("isLoggedIn") === "true" ? (
            currentRole === "admin" ? (
              <Route path="*" element={<Navigate to="/dashboard" />} />
            ) : (
              <Route path="*" element={<Navigate to="/dashboard" />} />
            )
          ) : (
            <Route
              path="*"
              element={<Navigate to="/authentication/sign-in" />}
            />
          )}
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && !isSignInOrSignOut && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Material Dashboard 2"
            routes={currentRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(currentRoutes)}
        {localStorage.getItem("isLoggedIn") === "true" ? (
          currentRole === "admin" ? (
            <Route path="*" element={<Navigate to="/dashboard" />} />
          ) : (
            <Route path="*" element={<Navigate to="/dashboard" />} />
          )
        ) : (
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        )}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
