import React from "react";
import { useNavigate } from "react-router-dom";

const SignOut = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.clear();
    localStorage.removeItem("authToken");
    // navigate('/authentication/sign-in');
    window.location.href = "https://app.pixacci.com/landing-page";
  }, []);

  return null; // or return a loading indicator if needed
};

export default SignOut;
