// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import {v4 as uuidv4} from "uuid";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import WhiteSelect from "../../components/WhiteSelect";
import { useEffect, useState } from "react";
import { DeleteLoungeById, GetLounge, GetChambers} from "../../Redux/action";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector, RootState } from "../../Redux/store";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toCapitalize } from "../../utils/capitalize";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
  Button,
  FormControl,

  InputLabel,
  MenuItem,
  SelectChangeEvent

} from "@mui/material";
import { useSnackbar } from "notistack";



const LoungeManagement: React.FC = () => {
  const Author = ({
    image,
    name,
    capacity,
  }: {
    image: string;
    name: string;
    capacity: number;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar
        {...({} as any)}
        src={image}
        //   name={name}
        size="sm"
      />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          having capacity of {capacity}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ price, discount }: { price: number; discount: number }) => (
    <MDBox {...({} as any)} lineHeight={1} textAlign="left">
      <MDTypography
        {...({} as any)}
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {price}
      </MDTypography>
      <MDTypography {...({} as any)} variant="caption">
        with discount of Rs. {discount}
      </MDTypography>
    </MDBox>
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isNationalChamberOrAggregator, setIsNationalChamberOrAggregator] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [selectedChamberId, setSelectedChamberId] = useState<null | string> ("");
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { lounges, loading } = useAppSelector(
    (store: RootState) => store.LoungeManagementReducer
  );
  const {chambers, loadingc} = useAppSelector(
    (store: RootState) => store.GetChambersReducer
  );
  const loungesData = lounges || [];
  const chambersData = chambers || [];

  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/lounge/edit/", { state: { type: "edit", id: id } });
    }
  };

  const handleClickOpen = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async (deleteId: number) => {
    try {
      await dispatch<any>(DeleteLoungeById(deleteId!));
      console.log("hy")
      const id = localStorage.getItem("user_id") as string;
      dispatch<any>(GetLounge(id));
      handleClose();
      enqueueSnackbar("Lounge is deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting Lounge:", error);
    }
  };




  const handleGetLounges = async () => {
    try {
      const id = localStorage.getItem("user_id") as string;
      await dispatch<any>(GetLounge(id));
    } catch (error) {
      console.error("Error fetching lounge data:", error);
    }
  };
  
  const handleGetChambers = async ()=>{
    try{
      const isNational = localStorage.getItem("isNationalChamber") as string;
      const isAggregator = localStorage.getItem("isAggregator") as string ;
      if(isNational || isAggregator){
        const boolNationalChamber=JSON.parse(isNational);
        const boolAggregator = JSON.parse(isAggregator);
        if(boolNationalChamber)setIsNationalChamberOrAggregator(boolNationalChamber);
        else if(boolAggregator)setIsNationalChamberOrAggregator(boolAggregator);
        
        if(boolNationalChamber || boolAggregator){
          await dispatch<any>(GetChambers());
          
        }

      }
    }catch(error){
      console.error("Error fetching Chamber detail: ", error);
    }
  }
  const handleChamberChange = (event: SelectChangeEvent<any>)=>{

    const chamberId = event.target.value;
    if(chamberId==="default"){
      handleGetLounges();
      setSelectedChamberId(chamberId);
      return;
    }
    setSelectedChamberId(chamberId);
    dispatch<any>(GetLounge(chamberId));

  }

  const columns = [
    { Header: "Lounge Name", accessor: "lounge_name", align: "left" },
    { Header: "City", accessor: "city", align: "left" },
    { Header: "Capacity", accessor: "capacity", align: "left" },
    { Header: "Booking Price", accessor: "booking_price", align: "left" },
    { Header: "Extended Hour Allowed", accessor: "extended_hour_allowed", align: "left" },
    { Header: "Status", accessor: "status", align: "left" },
    ...(selectedChamberId && selectedChamberId !== "default"
      ? []
      : [{ Header: "", accessor: "action", align: "center" }]),  // Capitalize "action"
  ];

  const rows = loungesData?.map((lounge) => {
    const currentDate = new Date();
    const bookingFrom = new Date(lounge.booking_from);
    const bookingTill = new Date(lounge.booking_till);
    const isActive = currentDate >= bookingFrom && currentDate <= bookingTill;

    return {
      lounge_name: (
        <Author
          image="https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
          name={toCapitalize(lounge?.name)}
          capacity={lounge.capacity}
        />
      ),
      city: (
        <MDTypography
          {...({} as any)}  
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {lounge?.city}
        </MDTypography>
      ),
      capacity: (
        <MDTypography
          {...({} as any)}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {lounge?.capacity}
        </MDTypography>
      ),
      booking_price: (
        <Job price={lounge?.booking_price} discount={lounge?.current_discount} />
      ),
      extended_hour_allowed: (
        <MDTypography
          {...({} as any)}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {toCapitalize(lounge?.extended_hour_allowed)}
        </MDTypography>
      ),
      status: (
        <MDBadge
          {...({} as any)}
          badgeContent={isActive ? "Active" : "Inactive"}
          color={isActive ? "success" : "error"}
          variant="gradient"
          size="sm"
        />
      ),
      action: selectedChamberId !== "default" && selectedChamberId ? null : (
        <MDBox {...({} as any)}  display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit">
            <IconButton color="primary" onClick={() => handleEditClick(lounge?.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                if (lounge?.id) handleClickOpen(lounge?.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    };
  });

  useEffect(() => {
    handleGetLounges();
    handleGetChambers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                    Lounge Management
                  </Typography>
                  {isNationalChamberOrAggregator ? (
                    <FormControl>
                     <InputLabel style={{ color: "black", backgroundColor:"white" }}>
                       Chapter
                     </InputLabel>
                     <WhiteSelect
                       label="Select Hall"
                       style={{ height: "45px", width: "200px" }}
                       sx={{
                          mb: 2,
                          mr: 1,
                          color: "#6c5ce7",
                          "& .MuiSelect-select": {
                            color: "black"
                          },
                          border: "1px  black solid",
                          "&:hover": {
                          color: "#6c5ce7",
                          border: "1px #6c5ce7 solid"
                       }}}
                       value={selectedChamberId}
                       onChange={handleChamberChange}
                       renderValue={(selectedId) => {
                        const selectedChamber =chambersData.find((chamber)=> chamber.chamber_id === selectedId);
                        return(
                            <div style={{ color: "black" }} className="selected">
                              {selectedChamberId === "default"
                                ? "Default"
                                : `${toCapitalize(selectedChamber?.first_name)} ${toCapitalize(selectedChamber?.last_name)}`}
                            </div>
                          )
                        }}
                      >
                       {chambersData.map((chamber) => (
                         <MenuItem sx={{color: "black"}} key={chamber.chamber_id} value={chamber.chamber_id}>
                           {toCapitalize(chamber?.first_name)} {toCapitalize(chamber?.last_name)}
                         </MenuItem>
                       ))}
                      <MenuItem sx={{color: "black"}} key={uuidv4()} value={"default"}>
                          {"Default"}
                      </MenuItem>
                     </WhiteSelect>
                    </FormControl>
                  ):null}
                <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                  <Tooltip title="View Lounge Bookings">
                    <Button
                      onClick = {() => navigate("/lounge-management/booking")}
                      variant="outlined"
                      sx={{
                        mb: 2,
                        mr: 1,
                        color: "#000",
                        border: "1px black solid",
                        "&:hover": {
                          color: "#6c5ce7",
                          border: "2px #6c5ce7 solid"
                        }
                      }}
                    >
                      View Bookings
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add a new Lounge">
                      <Button
                        onClick={() => {
                          navigate("/lounge/add", { state: { type: "add" } });
                        }}
                        variant="outlined"
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "#000",
                          border: "1px black solid",
                          "&:hover": {
                            color: "#6c5ce7",
                            border: "2px #6c5ce7 solid"
                          }
                        }}
                      >
                        Add Lounge
                      </Button>
                  </Tooltip>
                </MDBox>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {loungesData && !loading ? (
                  loungesData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No Lounge found. Please add a Lounge to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
              </Box>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this Lounge?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                <MDButton
                  {...({} as any)}
                  onClick={handleClose}
                  style={{
                    backgroundColor: 'initial', // Default background color
                    transition: 'background-color 0.3s', // Smooth transition for hover effect
                  }}
                  onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.currentTarget.style.backgroundColor = 'green';
                    e.currentTarget.style.color = 'white';
                  }}
                  onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.currentTarget.style.backgroundColor = 'initial';
                    e.currentTarget.style.color = 'gray';
                  }}
                >
                  No, Cancel
                </MDButton>

                  <MDButton
                    {...({} as any)}
                    onClick={handleDeleteClick}
                    autoFocus
                    style={{
                      backgroundColor: 'initial', // Default background color
                      transition: 'background-color 0.3s', // Smooth transition for hover effect
                    }}
                    onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.currentTarget.style.backgroundColor = 'red';
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.currentTarget.style.backgroundColor = 'initial';
                      e.currentTarget.style.color = 'gray';
                    }}
                  >
                    Yes, Agree
                  </MDButton>
                </DialogActions>
              </Dialog>              
            </Card>
          </Grid>
        </Grid>
        </Box>
    </DashboardLayout>
  );
};

export default LoungeManagement;
