import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, Typography, IconButton } from "@mui/material";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useAppDispatch } from "../../Redux/store";
import { GetEventsByChamberId } from "../../Redux/action";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface Event {
    id: string;
    title: string;
    start: string;
    end: string;
}

interface ApiResponse {
    success: boolean;
    data: Array<{
        event_id: string;
        chamber_id: string;
        event_name: string;
        event_date: string;
        event_time_from: string;
        event_date_end: string;
        event_time_till: string;
    }>;
}

const CalendarComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentMonth, setCurrentMonth] = useState<string>("");
    const calendarRef = useRef<any>(null);

    useEffect(() => {
        const fetchEvents = async () => {
            setLoading(true);
            setError(null);

            try {
                const chamber_id = localStorage.getItem("user_id");
                if (!chamber_id) {
                    throw new Error("Chamber ID is not available");
                }

                const response: ApiResponse = await dispatch<any>(GetEventsByChamberId(chamber_id));

                if (Array.isArray(response)) {
                    const formattedEvents = response.map((event: any) => ({
                        id: event.event_id.toString(),
                        calendarId: event.chamber_id.toString(),
                        title: event.event_name,
                        category: 'time',
                        start: `${event.event_date}T${event.event_time_from}`,
                        end: `${event.event_date_end}T${event.event_time_till}`,
                    }));

                    console.log(formattedEvents);

                    setEvents(formattedEvents);
                    console.log("Events:", formattedEvents);
                } else {
                    throw new Error("Invalid response structure: expected an array");
                }

            } catch (error: any) {
                console.error("Error fetching events:", error);
                setError(error.message || "An error occurred while fetching events");
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [dispatch]);

    const updateCurrentMonth = () => {
        const date = calendarRef.current.getInstance().getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        setCurrentMonth(`${months[month]} ${year}`);
    };

    const handleNextMonth = () => {
        calendarRef.current.getInstance().next();
        updateCurrentMonth();
    };

    const handlePrevMonth = () => {
        calendarRef.current.getInstance().prev();
        updateCurrentMonth();
    };

    useEffect(() => {
        updateCurrentMonth();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    height: "calc(100vh - 64px)",
                    px: 3,
                    py: 2
                }}
            >
                <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                        py={2}
                    >
                        <Typography variant="h5" color="black">
                            Calendar
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={handlePrevMonth} color="primary" sx={{ mr: 2 }}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {currentMonth}
                            </Typography>
                            <IconButton onClick={handleNextMonth} color="primary" sx={{ ml: 2 }}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box flexGrow={1} sx={{ p: 3 }}>
                        <Calendar
                            ref={calendarRef}
                            height="100%"
                            view="month"
                            events={events}
                            useDetailPopup={false}
                        />
                    </Box>
                </Card>
            </Box>
        </DashboardLayout>
    );
};

export default CalendarComponent;