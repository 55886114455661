/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Certificate } from "crypto";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDAvatar from "../../../../components/MDAvatar";
import MDButton from "../../../../components/MDButton";
// Material Dashboard 2 React base styles
import breakpoints from "../../../../assets/theme/base/breakpoints";

// Images
// import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { GetChamberById } from "../../../../Redux/action";
import { RootState, useAppSelector } from "../../../../Redux/store";
import { useAppDispatch } from "../../../../Redux/store";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Chamber } from "../../../../utils/types";

function Header({ children }: any) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const docUrl = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/";
  const navigate = useNavigate();
  const dispatchChamber = useAppDispatch();
  const { chamberById } = useAppSelector(
    (store: RootState) => store.MembershipReducer
  );
  const chamber_id: number | string = localStorage.getItem("user_id")!;
  const chamberData = chamberById;
  // console.log("chamberData", chamberById);
  const handleEditClick = (chamberData: Chamber | null) => {
    if (chamberData?.chamber_id) {
      navigate("/chamber/edit/", { state: { type: "edit", chamberData:chamberData, chamber_id:chamberData?.chamber_id } });
    }
  };
  useEffect(() => {
    dispatchChamber<any>(GetChamberById(+chamber_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);


  const generatePDF = async (chamberData: any, docUrl: string) => {
    const doc = new jsPDF();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text("Membership Certificate", 60, 20);

    const logoUrl = chamberData?.logo ? `${docUrl}${chamberData.logo}` : null;

    if (logoUrl) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = logoUrl;

      await new Promise((resolve) => {
        img.onload = () => {
          doc.addImage(img, "PNG", 75, 30, 60, 30);
          resolve(true);
        };
      });
    }

    doc.setFont("helvetica", "normal");
    doc.setFontSize(14);
    doc.text(`Chamber Name: ${chamberData?.first_name || ""} ${chamberData?.last_name || ""}`, 10, 80);
    doc.text(`Membership From: ${chamberData?.subscription_from?.slice(0, 10) || "N/A"}`, 10, 90);
    doc.text(`Membership Till: ${chamberData?.subscription_till?.slice(0, 10) || "N/A"}`, 10, 100);

    doc.save("Membership_Certificate.pdf");
  };

  const handleSetTabValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: number
  ) => setTabValue(newValue);

  return (
    <MDBox {...({} as any)} position="relative" mb={5}>
      <MDBox
        {...({} as any)}
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            // @ts-ignore
            functions: { rgba, linearGradient },
            // @ts-ignore
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 8,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              {...({} as any)}
              src={`https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/${chamberData?.logo}`}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox {...({} as any)} height="100%" mt={0.5} lineHeight={1}>
              <MDTypography {...({} as any)} variant="h5" fontWeight="medium">
                {`${chamberData?.first_name} ${chamberData?.last_name}`}
              </MDTypography>
              <MDTypography
                {...({} as any)}
                variant="button"
                color="text"
                fontWeight="regular"
              >
                Admin
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              {/* <Tabs
                {...({} as any)}
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab
                  label="Profile information"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      home
                    </Icon>
                  }
                />
                
              </Tabs> */}

             <div style={{display:"flex", justifyContent:"flex-end"}}>
                <Tooltip title="Download Membership Certificate">
                  <MDButton
                    {...({} as any)}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={() => generatePDF(chamberData, docUrl)}
                  >
                    Membership Certificate
                  </MDButton>

                </Tooltip>
              <Tooltip title="Edit">
                  <IconButton color="primary" sx={{ ml: 2 }} onClick={() => handleEditClick(chamberData)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
             </div>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
