import { useState, useEffect } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
// Authentication layout components
import {
  CircularProgress,
  Switch,
  Typography,
  Box,
  Button,
  TextField,
  Link,
  Card,
  IconButton,
  InputAdornment,
  Container,
  Grid,
  Alert,
} from "@mui/material";
// Images
import bgImage from "../../../assets/SaaS_SignIn.png";
import chamberLogo from "../../../assets/chamber_logo.png";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CryptoJS from "crypto-js";
import { useSearchParams } from "react-router-dom";

interface Credentials {
  email: string;
  password: string;
}

function Basic() {

  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState<'email' | 'otp' | 'changePassword'>('email');
  const [error, setError] = useState<string | null>(null);
 
  const [cred, setCred] = useState<Credentials>({ email: "", password: "" });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleClickShowPassword = (): void => setShowPassword((show) => !show);
  const [logo, setLogo] = useState<string | null>(null);
  const [searchParams] = useSearchParams(); 
  const [isForgortPassword, setIsForgotPassword] = useState<boolean>(false);

  const generateOtp = async () => {
    const user = { email: email };
    const response = await axios.post('https://api.pixacci.com/user/forgetpassword', user);
    console.log(response);
    if (response.data.success) {
      setStep('otp');
      setError(null);
    } else {
      setError(response.data.message);
      enqueueSnackbar(response.data.message);
    }
  };

  const validateOtp = async () => {
    const userWithOtp = { email: email, otp: otp };
    const response = await axios.post('https://api.pixacci.com/user/matchotp', userWithOtp);

    if (response.data.success) {
      setStep('changePassword'); // Reset to email step
    } else {
      setError('Invalid OTP. Please try again.');
      enqueueSnackbar('Invalid OTP. Please try again.');
    }
  };

  const changePassword = async () => {
    // Logic to change the password
    if (newPassword === confirmPassword) {
      const userWithPassword = { email: email, otp: otp, newPassword: newPassword };
      const response = await axios.post('https://api.pixacci.com/user/createnewpassword', userWithPassword);
      console.log(response);
      if (response.data.success) {
        console.log('Password changed successfully to', newPassword);
        setStep('email');
        setNewPassword('');
        setConfirmPassword('');
        setIsForgotPassword(false);
        setError(null);
      } else {
        enqueueSnackbar(response.data.message);
      }
    } else {
      setError('Passwords do not match.');
      enqueueSnackbar('Passwords do not match.');
    }
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCred({
      ...cred,
      [name]: value,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const encryptedCredentials = params.get("data");
  
    if (encryptedCredentials) {
      const login = async () => {
        try {
          // AES Decryption
          const decryptedBytes = CryptoJS.AES.decrypt(
            decodeURIComponent(encryptedCredentials),
            process.env.REACT_APP_SECRET_KEY || "final"
          );
          const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);

          const credentials = JSON.parse(decryptedString);

          const { email, password } = credentials;

          const apiUrl = "https://api.pixacci.com/chamber/login";
          const updatedCred = {
            email,
            password,
            role: "chamber",
          };

          const response: AxiosResponse<{
            success: boolean;
            message: {
              user_id: string;
              accessToken: string;
              is_national_chamber: boolean;
              is_aggregator: boolean;
            };
          }> = await axios.post(apiUrl, updatedCred);

          if (response.data.success) {
            const token = response.data.message.accessToken;

            const decodedToken = JSON.parse(atob(token.split(".")[1]));

            if (decodedToken.role === "SUPER_ADMIN") {
              enqueueSnackbar("Login failed, Super Admin cannot log in Chamber Admin Panel", {
                variant: "error",
              });
              return;
            }

            localStorage.setItem("user_id", response.data.message.user_id);
            localStorage.setItem("token", response.data.message.accessToken);
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("role", "admin");
            localStorage.setItem(
              "isNationalChamber",
              JSON.stringify(response.data.message.is_national_chamber)
            );
            localStorage.setItem(
              "isAggregator",
              JSON.stringify(response.data.message.is_aggregator)
            );

            enqueueSnackbar("Logged in successfully.", { variant: "success" });
            navigate("/dashboard");
            window.location.reload();
          } else {
            enqueueSnackbar("Login failed, something went wrong.", {
              variant: "error",
            });
          }
        } catch (error: AxiosError | any) {
          enqueueSnackbar(
            "Login failed, Check your credentials or User not found.",
            { variant: "error" }
          );
          // console.log(error);
        }
      };

      login();
    }
  }, [enqueueSnackbar, navigate]);

  useEffect(() => {
    const clientId = searchParams.get("client");

    if (clientId) {
      const fetchLogo = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `https://api.pixacci.com/chamber/logo/${clientId}`
          );

          if (response.data?.logo) {
            setLogo(response.data.logo);
          } else {
            enqueueSnackbar("No client found. Redirecting...", {variant: "error"});
            setTimeout(() => {
              window.location.href = "https://app.pixacci.com/landing-page";
            }, 3000);
          }
        } catch (error) {
          console.error("Error fetching client logo:", error);
          enqueueSnackbar("No client found. Redirecting...", {variant: "error"});
          setTimeout(() => {
            window.location.href = "https://app.pixacci.com/landing-page";
          }, 3000);
          } finally {
          setLoading(false);
        }
      };

      fetchLogo();
    } else {
      setLoading(false);
    }
  }, [searchParams]);


  const handleLogin = async () => {
    try {
      const apiUrl = "https://api.pixacci.com/chamber/login";
      const updatedCred = { ...cred, role: "chamber" };

      const response: AxiosResponse<{
        success: boolean;
        message: {
          user_id: string;
          accessToken: string;
          is_national_chamber: boolean;
          is_aggregator: boolean;
        };
      }> = await axios.post(apiUrl, updatedCred);

      if (response.data.success) {
        const token = response.data.message.accessToken;

        const decodedToken = JSON.parse(atob(token.split(".")[1]));

        if (decodedToken.role === "SUPER_ADMIN") {
          enqueueSnackbar("Login failed, Super Admin cannot log in Chamber Admin Panel", {
            variant: "error",
          });
          return;
        }

        localStorage.setItem("user_id", response.data.message.user_id);
        localStorage.setItem("token", token);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("role", "admin");
        localStorage.setItem(
          "isNationalChamber",
          JSON.stringify(response.data.message.is_national_chamber)
        );
        localStorage.setItem(
          "isAggregator",
          JSON.stringify(response.data.message.is_aggregator)
        );
        enqueueSnackbar("Logged in successfully.", { variant: "success" });
        navigate("/dashboard");
        window.location.reload();
      } else {
        enqueueSnackbar("Login failed, something went wrong.", {
          variant: "error",
        });
        //console.log("Login failed, something went wrong.");
      }
    } catch (error: AxiosError | any) {
      enqueueSnackbar(
        "Login failed, Check your credentials or User not found.",
        { variant: "error" }
      );
      // console.log(error);
    }
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="stretch"
      minHeight="100vh"
      sx={{ backgroundColor: "#f5f5f5" }}
    >
    
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "3rem",
          backgroundColor: "#fff",
          position: "relative",
          marginTop: "-8rem",
        }}
      >
        <Card
          sx={{
            width: "100%",
            maxWidth: "400px",
            padding: "2rem",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >


          <Box
            display="flex"
            justifyContent="center"
            sx={{ marginBottom: "1rem" }}
          >
            <img
              src={logo || chamberLogo}
              alt="Chamber Logo"
              style={{ maxWidth: "150px", height: "auto" }}
            />
          </Box>
          <Typography
            variant="h3"
            align="center"
            fontWeight="bold"
            gutterBottom
            sx={{ marginBottom: "2rem", color: "#6c5ce7" }}
          >
            Welcome, Back!
          </Typography>
          {isForgortPassword?(
            <>
              {/* <Container maxWidth="lg" sx={{ mt: 1 }}> */}
                <Box
                  sx={{
                    borderRadius: 2,
                    textAlign: 'center'
                  }}
                >
                  
                  <Typography variant="h5" mb={3}>
                    {step === 'email' ? 'Enter Your Email' : 'Verify OTP'}
                  </Typography>

                  {step === 'email' && (
                    <>
                      <TextField
                        
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        sx={{ mb: 3 }}
                      />
                      <Button variant="contained" color="primary" fullWidth onClick={generateOtp} disabled={!email}>
                        Get OTP
                      </Button>
                    </>
                  )}

                  {step === 'otp' && (
                    <>
                      <Typography mb={2} color="textSecondary">
                        OTP sent to {email}
                      </Typography>
                      <TextField
                        label="Enter OTP"
                        variant="outlined"
                        fullWidth
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        type="text"
                        sx={{ mb: 3 }}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Button variant="outlined" color="secondary" fullWidth onClick={() => setStep('email')}>
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button variant="contained" color="primary" fullWidth onClick={validateOtp} disabled={!otp}>
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                      {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          {error}
                        </Alert>
                      )}
                    </>
                  )}

                  {step === 'changePassword' && (
                    <>
                      <TextField
                        label="New Password"
                        variant="outlined"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type="password"
                        sx={{ mb: 3 }}
                      />
                      <TextField
                        label="Confirm Password"
                        variant="outlined"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        sx={{ mb: 3 }}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Button variant="outlined" color="secondary" fullWidth onClick={() => setStep('otp')}>
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={changePassword}
                            disabled={!newPassword || newPassword !== confirmPassword}
                          >
                            Change Password
                          </Button>
                        </Grid>
                      </Grid>
                      {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          {error}
                        </Alert>
                      )}
                    </>
                  )}
                </Box>
                <button
                  onClick={() => setIsForgotPassword(!isForgortPassword)}
                  style={{
                    background: 'none',
                    color: '#1890FF',
                    border:"none",
                    cursor: 'pointer',
                    marginBottom:"10px",
                    marginTop:"10px",
                    display:"flex",
                    justifyContent:"flex-start"
                  }}
                >
                  Remember Password ?
                </button>
              {/* </Container> */}
          </>
          ):(
            <>
            <Typography
            variant="subtitle2"
            align="center"
            sx={{ marginBottom: "2rem", color: "#6c757d" }}
          >
            Enter your email and password to sign in
          </Typography>

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            name="email"
            onChange={handleChange}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            name="password"
            onChange={handleChange}
            sx={{ marginBottom: "1rem" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: "1rem" }}
          >
            <Box display="flex" alignItems="center">
              <Switch
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                color="primary"
              />
              <Typography variant="body2" sx={{ marginLeft: "0.5rem" }}>
                Remember me
              </Typography>
            </Box>
            
            
          </Box>
          <button
              onClick={() => setIsForgotPassword(!isForgortPassword)}
              style={{
                background: 'none',
                color: '#1890FF',
                border:"none",
                cursor: 'pointer',
                marginBottom:"10px",
                display:"flex",
                justifyContent:"flex-start"
              }}
            >
              Forgot Password?
          </button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#6c5ce7",
              color: "#fff",
              padding: "0.75rem",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "#5e4cc7" },
            }}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign In"
            )}
          </Button>
          </>
          )}
          
       

     
        </Card>

        {/* <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            padding: "1rem",
            color: "#6c757d",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="body2">
            Made by{" "}
            <Link
              href="https://pixaflip.com/"
              target="_blank"
              sx={{ color: "#6c5ce7", textDecoration: "none" }}
            >
              Pixaflip
            </Link>
          </Typography>
        </Box>*/}
      </Box> 

      <Box
        sx={{
          width: "50%",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </Box>
  );
}

export default Basic;
