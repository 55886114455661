import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Box,
  Typography,
  Button
} from "@mui/material";
import {
  DeleteEquipmentsById,
  GetAllEquipmentsByHallId,
  GetHalls,
} from "../../Redux/action";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../examples/Tables/DataTable";
import { useSnackbar } from "notistack";
import { RootState, useAppDispatch, useAppSelector } from "../../Redux/store";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";

const WhiteSelect = styled(Select)({
  color: "white",
  borderColor: "white",
  "&:focus": {
    color: "white",
  },
  "& .selected": {
    color: "white",
  },
});

const EquipmentManagement: React.FC = () => {
  const Author: React.FC<{ image?: string; name: string }> = ({
    image,
    name,
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job: React.FC<{ price: number; discount: number }> = ({
    price,
    discount,
  }) => (
    <MDBox {...({} as any)} lineHeight={1} textAlign="left">
      <MDTypography
        {...({} as any)}
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {price}
      </MDTypography>
      <MDTypography {...({} as any)} variant="caption">
        ₹{discount} discount for the members
      </MDTypography>
    </MDBox>
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { halls, loading } = useAppSelector(
    (store: RootState) => store.HallManagementReducer
  );
  const { equipsByHallId } = useAppSelector(
    (store: RootState) => store.EquipmentReducer
  );
  const hallsData = halls || [];
  const equipsByHallIdData = equipsByHallId || [];
  const [selectedHall, setSelectedHall] = useState<null | string>("");
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  // console.log(equipsByHallIdData);

  const handleClickOpen = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (id: number) => {
    navigate("/equipment-management/edit/", {
      state: { type: "edit", id: id },
    });
  };

  const handleDeleteClick = async () => {
    try {
      await dispatch<any>(DeleteEquipmentsById(deleteId!));
      dispatch<any>(GetAllEquipmentsByHallId(+selectedHall!));
      handleClose();
      enqueueSnackbar("Equipment is deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting equipment:", error);
    }
  };

  const handleHallChange = (event: SelectChangeEvent<any>) => {
    const hallId = event.target.value;
    setSelectedHall(hallId);
    dispatch<any>(GetAllEquipmentsByHallId(hallId));
  };

  const columns = [
    {
      Header: "Equipment Name",
      accessor: "equipment name",
      align: "left",
    },
    {
      Header: "Description",
      accessor: "description",
      width: "40%",
      align: "left",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      align: "left",
    },
    {
      Header: "Price",
      accessor: "price",
      align: "left",
    },
    {
      Header: "Action",
      accessor: "action",
      align: "center",
    },
  ];

  const rows = equipsByHallIdData?.map((equips) => ({
    "equipment name": (
      <Author
        // image={"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Shure_mikrofon_55S.jpg/255px-Shure_mikrofon_55S.jpg"}
        name={equips?.equipment_name}
      />
    ),
    description: (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {equips?.equipment_desc}
      </MDTypography>
    ),
    quantity: (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {equips?.equipment_quantity}
      </MDTypography>
    ),
    price: (
      <Job price={equips?.booking_price} discount={equips?.member_discount} />
    ),
    action: (
      <>
        <Tooltip title="Edit">
          <IconButton color="primary" 
          onClick={() => {
            if (equips.equipment_id) handleEditClick(equips?.equipment_id);
          }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" 
          onClick={() => {
            if (equips.equipment_id) handleClickOpen(equips?.equipment_id);
          }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    ),
  }));

  const handleGetHallsName = async () => {
    try {
      const id = localStorage.getItem("user_id") as string;
      await dispatch<any>(GetHalls(id));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetHallsName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("hallsData", hallsData);
  // console.log("equipsByHallId", equipsByHallIdData);
  // console.log("selectedHall", selectedHall);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                    Equipment Management
                  </Typography>
                <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                  <FormControl>
                    <InputLabel style={{ color: "black",backgroundColor:"white" }}>
                      Select Hall
                    </InputLabel>
                    <WhiteSelect
                      label="Select Hall"
                      style={{ height: "45px", width: "200px" }}
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "black",
                          "& .MuiSelect-select": {
                            color: "black",
                          },
                          "&:hover": {
                            color: "#6c5ce7",
                          },
                        }}
                      value={selectedHall}
                      onChange={handleHallChange}
                      renderValue={(selectedId) => (
                        <div style={{ color: "black" }} className="selected">
                          {
                            hallsData.find((hall) => hall.id === selectedId)
                              ?.name
                          }
                        </div>
                      )}
                    >
                      {hallsData.map((hall) => (
                        <MenuItem sx={{color: "black"}} key={hall.id} value={hall.id}>
                          {hall?.name}
                        </MenuItem>
                      ))}
                    </WhiteSelect>
                  </FormControl>
                </MDBox>
                </Box>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {selectedHall ? (
                  equipsByHallIdData && !loading ? (
                    equipsByHallIdData?.length > 0 ? (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    ) : (
                      <Grid
                        style={{
                          display: "grid",
                          placeItems: "center",
                          margin: "11.5%",
                        }}
                      >
                        <img
                          src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                          width={"80px"}
                          height={"80px"}
                          alt=""
                        />
                        <MDTypography {...({} as any)} fontSize="12px">
                          No equipment found. Please add an equipment to
                          continue.
                        </MDTypography>
                      </Grid>
                    )
                  ) : (
                    <span style={{ fontSize: "15px", margin: "2rem" }}>
                      Loading...
                    </span>
                  )
                ) : (
                  <Grid
                    style={{
                      display: "grid",
                      placeItems: "center",
                      margin: "11.5%",
                    }}
                  >
                    <img
                      src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                      width={"80px"}
                      height={"80px"}
                      alt=""
                    />
                    <MDTypography {...({} as any)} fontSize="12px">
                      Please select a hall to continue.
                    </MDTypography>
                  </Grid>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this equipment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton {...({} as any)} onClick={handleClose}>
            Disagree
          </MDButton>
          <MDButton {...({} as any)} onClick={handleDeleteClick} autoFocus>
            Agree
          </MDButton>
        </DialogActions>
      </Dialog>
      <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
        <MDButton
          {...({} as any)}
          variant="gradient"
          style={{ color: 'white', backgroundColor: '#6c5ce7' }}
          type="submit"
          onClick={() => {
            navigate(`/equipment-management/add`, {
              state: { type: "add", hallId: selectedHall },
            });
          }}
          disabled={!selectedHall}
        >
          Add Equipment
        </MDButton>
      </Grid>
    </DashboardLayout>
  );
};

export default EquipmentManagement;
