import * as yup from "yup";

const addUserValidations = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required.")
    .matches(/^[A-Za-z\s]*$/, "First name must contain only alphabets."),
  last_name: yup
    .string()
    .required("Last name is required.")
    .matches(/^[A-Za-z\s]*$/, "last name must contain only alphabets."),
  phone_number: yup
    .string()
    .required("Phone number is required.")
    .matches(/^[0-9]{10}$/, "Phone number must contain exactly 10 digits."),
  contact_person: yup.string().required("Contact person is required"),
  email: yup
    .string()
    .test("is-valid-email", "Invalid email address.", (value) => {
      if (!value) {
        return true;
      }
      const parts = value.split("@");
      if (parts.length !== 2) {
        return false;
      }
      const [localPart, domainPart] = parts;
      return (
        /^[A-Za-z0-9.-]+$/.test(localPart) && /^[A-Za-z.-]+$/.test(domainPart)
      );
    })
    .required("Email is required."),
  // password: yup
  //     .string()
  //     .required("Password is required"),
  address: yup
    .string()
    .required("Street address is required. Please provide a complete address (e.g., '123 Main St, Apt 4B').")
    .matches(
      /^[a-zA-Z0-9\s,.\-]+$/,
      "Street address can only contain letters, numbers, spaces, commas, periods, and hyphens."
    ),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  pincode: yup
    .string()
    .required("Pincode is required")
    .matches(
      /^[0-9]{6}$/,
      "Pincode must be exactly 6 digits and can contain only numbers."
    ),
  gst_number: yup
    .string()
    .required("GST number is required")
    .matches(
      /^[A-Za-z0-9]{15}$/,
      "GST number must be exactly 15 characters and can contain alphanumeric characters."
    ),
  organization_name: yup.string().required("Organization name is required"),
  //     .matches(/^[A-Za-z\s]*$/, "Organization name must contain only alphabets."),
  organization_category: yup
    .string()
    .required("Organization category is required"),
  subscription_from: yup
    .string()
    .required("Subscription starting date is required"),
  subscription_till: yup
    .string()
    .required("Subscription ending date is required"),

  // status: yup
  //     .string()
  //     .required("Status is required"),
});

const addHallValidations = yup.object().shape({
  name: yup
    .string()
    .required("Hall name is required.")
    .matches(/^[A-Za-z\s]*$/, "Hall name must contain only alphabets."),

  capacity: yup.number().required("Capacity is required."),
  address: yup
    .string()
    .required("Street address is required. Please provide a complete address (e.g., '123 Main St, Apt 4B').")
    .matches(
      /^[a-zA-Z0-9\s,.\-]+$/,
      "Street address can only contain letters, numbers, spaces, commas, periods, and hyphens."
    ),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  pincode: yup.string().required("Pincode is required."),
  contact_person: yup.string().required("Contact person name is required."),
  contact_number: yup
    .string()
    .required("Pincode is required.")
    .matches(
      /^[0-9]{10}$/,
      "Contact person phone number must contain exactly 10 digits."
    ),

  booking_price: yup.number().required("Booking price is required."),

  current_discount: yup.number().required("Discount is required."),

  applicable_deposit: yup.number().required("Applicable deposit is required."),

  member_price: yup.number().required("Member price is required."),

  nonmember_price: yup.number().required("Non-member price is required."),

  extended_hour_allowed: yup.string(),

  // extended_hours: yup
  //     .number(),

  booking_from: yup
    .string()
    .required("Booking availability start time is required."),

  booking_till: yup
    .string()
    .required("Booking availability end time is required."),
});

const addLoungeValidations = yup.object().shape({
  name: yup
    .string()
    .required("Lounge name is required.")
    .matches(/^[A-Za-z\s]*$/, "Lounge name must contain only alphabets."),

  capacity: yup.number().required("Capacity is required."),

  address: yup
    .string()
    .required("Street address is required. Please provide a complete address (e.g., '123 Main St, Apt 4B').")
    .matches(
      /^[a-zA-Z0-9\s,.\-]+$/,
      "Street address can only contain letters, numbers, spaces, commas, periods, and hyphens."
    ),
      state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  pincode: yup.string().required("Pincode is required."),
  contact_person: yup.string().required("Contact person name is required."),
  contact_number: yup
    .string()
    .required("Contact person phone number is required.")
    .matches(
      /^[0-9]{10}$/,
      "Contact person phone number must contain exactly 10 digits."
    ),

  current_discount: yup.number().required("Discount is required."),

  applicable_deposit: yup.number().required("Applicable deposit is required."),

  member_price: yup.number().required("Member price is required."),

  nonmember_price: yup.number().required("Non-member price is required."),

  extended_hour_allowed: yup.string(),

  // extended_hours: yup
  //     .number(),

  booking_from: yup
    .string()
    .required("Booking availability start time is required."),

  booking_till: yup
    .string()
    .required("Booking availability end time is required."),
});

const addEquipmentValidations = yup.object().shape({
  equipment_name: yup.string().required("Equipment Name is required."),
  equipment_quantity: yup
    .number()
    .typeError("Equipment Quantity must be a number.")
    .required("Equipment Quantity is required."),
  equipment_desc: yup.string().required("Equipment Description is required."),
  booking_price: yup
    .number()
    .typeError("Price must be a number.")
    .required("Price is required."),
  member_discount: yup
    .number()
    .typeError("Discount must be a number.")
    .required("Discount for the member is required."),
});

const eventValidations = yup.object().shape({
  event_name: yup.string().required("Event Name is required."),
  event_date: yup.string().required("Event Date is required."),
  event_time_from: yup.string().required("Start time is required."),
  event_time_till: yup.string().required("End time is required."),
  event_agenda: yup.string().required("Event Agenda is required."),
  event_desc: yup.string().required("Event Description is required."),
  event_registration_date: yup
    .string()
    .required("Last Registration Date is required."),
});

export {
  addUserValidations,
  addHallValidations,
  addLoungeValidations,
  addEquipmentValidations,
  eventValidations,
};
