import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import markerIconPng from "leaflet/dist/images/marker-icon.png";

interface MapComponentProps {
  onPositionChange: (position: { lat: number; lng: number }) => void;
  storedMapLocation?: { lat: number; lng: number };
}

const RecenterMap = ({ lat, lng, zoom }: { lat: number; lng: number; zoom: number }) => {
  const map = useMap();

  useEffect(() => {
    if (lat && lng) {
      map.flyTo([lat, lng], zoom, { animate: true, duration: 1.5 });
    }
  }, [lat, lng]);

  return null;
};

const MapComponent: React.FC<MapComponentProps> = ({ onPositionChange, storedMapLocation }) => {
  const [currentPosition, setCurrentPosition] = useState<{ lat: number; lng: number }>(() => {
    if (storedMapLocation && storedMapLocation.lat && storedMapLocation.lng) {
      return storedMapLocation;
    }
    return { lat: 18.5204, lng: 73.8567 };
  });

  const [zoom, setZoom] = useState(13);
  const location = useLocation();
  const type = location?.state?.type;

  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  useEffect(() => {
    if (storedMapLocation && storedMapLocation.lat && storedMapLocation.lng) {
      setCurrentPosition(storedMapLocation);

      if (type !== "add") {
        setZoom(15);
      }
    }
  }, [storedMapLocation, type]);

  useEffect(() => {
    if (navigator.geolocation && type === "add") {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const { latitude, longitude } = coords;
          const newPosition = { lat: latitude, lng: longitude };
          setCurrentPosition(newPosition);
          onPositionChange(newPosition);
        },
        (err) => {
          console.warn("Geolocation error:", err);
          onPositionChange(currentPosition);
        },
        { enableHighAccuracy: true }
      );
    }
  }, [type, onPositionChange]);

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        const newPosition = { lat, lng };
        setCurrentPosition(newPosition);
        onPositionChange(newPosition);
      },
    });
    return null;
  };

  return (
    <MapContainer
      center={currentPosition}
      zoom={zoom}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <RecenterMap lat={currentPosition.lat} lng={currentPosition.lng} zoom={zoom} />
      <Marker position={currentPosition} icon={customIcon} draggable={true}
        eventHandlers={{
          dragend: (e) => {
            const marker = e.target;
            const position = marker.getLatLng();
            const newPosition = { lat: position.lat, lng: position.lng };
            setCurrentPosition(newPosition);
            onPositionChange(newPosition);
          },
        }}
      />
      <MapClickHandler />
    </MapContainer>
  );
};

export default MapComponent;